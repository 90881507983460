import { IdsMainButton } from '@ids/react'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import AdditionalInfo from '../components/AdditionalInfo'
import '../final-screen.scss'
import { useResult } from '../hooks'

const ItauAccount = () => {
  useResult({})
  const proposalContext = useProposal()
  const link = proposalContext?.product?.proposal?.link
  const productCode = proposalContext?.product?.proposal?.productCode

  return (
    <Layout>
      <div className="final_screen">
        <div className="final_screen__content">
          <h1 className="final_screen__title">
            Acesse a página para abrir a sua conta Itaú
          </h1>
          <p className="final_screen__text">
            Abra uma conta com a gente sem mensalidades, totalmente grátis
          </p>

          <div className="final_screen__buttons-wrapper">
            <a href={link} target="_blank" rel="noreferrer">
              <IdsMainButton
                full={true}
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'telas-finais-de-redirect-do-produto',
                    detail: productCode
                  })
                }}
              >
                Acessar a página
              </IdsMainButton>
            </a>

            <SeeOffersButton isCrossSell />

            <AdditionalInfo />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ItauAccount
